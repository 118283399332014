@font-face {
  font-family: Normal;
  src: url("/assets/fonts/OpenSans-Regular.ttf");
}

@font-face {
  font-family: Bold;
  src: url("/assets/fonts/OpenSans-Bold.ttf");
}

@font-face {
  font-family: SemiBold;
  src: url("/assets/fonts/OpenSans-Semibold.ttf");
}

@font-face {
  font-family: Light;
  src: url("/assets/fonts/OpenSans-Light.ttf");
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: Icon;
  src:
          url("/assets/fonts/FontAwesome.otf"),
          url("/assets/fonts/fontawesome-webfont.eot?v=4.7.0"),
          url("/assets/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0") format("embedded-opentype"),
          url("/assets/fonts/fontawesome-webfont.woff2?v=4.7.0") format("woff2"),
          url("/assets/fonts/fontawesome-webfont.woff?v=4.7.0") format("woff"),
          url("/assets/fonts/fontawesome-webfont.ttf?v=4.7.0") format("truetype"),
          url("/assets/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
  font-weight: normal;
  font-style: normal;
}
